import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Body } from '@components/styled/Typography';
import { ReactComponent as PolygonComponent } from '@icons/wolfkit-light/polygon-up.svg';
const PriceQuotationContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'end',
    userSelect: 'none',
    color: props.theme.palette.text.disabled,
}));
const PriceTrendContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    lineHeight: '18px',
}));
const Text = styled(Body)(() => ({
    display: 'inline',
    fontSize: 18,
    lineHeight: '22px',
}));
const ValueToSellText = styled(Text)(props => ({
    marginRight: props.theme.spacing_sizes.xs * 0.5,
}));
const PriceText = styled(Text)(props => ({
    marginRight: props.theme.spacing_sizes.xs * 0.5,
}));
const Percentage = styled(Text)(() => ({
    fontSize: 12,
    lineHeight: '18px',
}));
const PolygonUp = styled(PolygonComponent) `
    color: ${props => props.theme.palette.text.disabled};
`;
const PolygonDown = styled(PolygonUp) `
    transform: rotateX(180deg);
`;
const renderPolygon = (trend) => {
    switch (trend) {
        case 'down':
            return _jsx(PolygonDown, {});
        case 'up':
            return _jsx(PolygonUp, {});
        default:
            return null;
    }
};
/**
 * component for displaying info as ratio
 */
const PriceQuotation = ({ valueToBy = undefined, valueToSell = undefined, price = undefined, percentage = undefined, trend = undefined, }) => (_jsxs(PriceQuotationContainer, { children: [_jsx(ValueToSellText, { children: valueToBy && valueToSell ? `${valueToBy || ''}/${valueToSell || ''}` : '' }), _jsx(PriceText, { children: price || '' }), _jsxs(PriceTrendContainer, { children: [renderPolygon(trend), _jsx(Percentage, { children: percentage ? `${percentage} %` : '' })] })] }));
export default PriceQuotation;
