var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import PriceQuotation from '../PriceQuotation';
const PanelContainer = styled.div `
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    padding-top: 38px;

    width: inherit;
`;
const Divider = styled.div `
    display: flex;
    width: 0;
    height: 22px;
    border-right: 2px solid ${props => props.theme.customColors.menu.border};
`;
const PriceQuotationWithDivider = (_a) => {
    var { removeDivider } = _a, props = __rest(_a, ["removeDivider"]);
    return (_jsxs(_Fragment, { children: [_jsx(PriceQuotation, Object.assign({}, props)), !removeDivider && (_jsx(Divider, {}))] }));
};
const QuotationPanel = ({ quotations }) => (_jsx(PanelContainer, { children: quotations.map((quotation, index) => (_jsx(PriceQuotationWithDivider, Object.assign({ removeDivider: (quotations.length > 1 &&
            (index < 0 || index === quotations.length - 1)) }, quotation), index))) }));
export default QuotationPanel;
